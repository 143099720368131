//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const MODAL_SIZE_SMALL = 'small';
const MODAL_SIZE_MEDIUM = 'medium';

export default {
    name: 'StageModal',
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        headline: {
            type: String,
            default: null,
        },
        size: {
            type: String,
            default: MODAL_SIZE_MEDIUM,
            validator: v => [MODAL_SIZE_SMALL, MODAL_SIZE_MEDIUM].includes(v),
        },
    },
    data() {
        return {
            headlineId: `modal-headline-${this._uid}`,
            MODAL_SIZE_SMALL,
            MODAL_SIZE_MEDIUM,
            lastActiveElement: null,
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        handleEscKey(e) {
            if (e.key === 'Escape' && this.active) {
                this.close();
            }
        },
        trapFocus(e) {
            if (!this.$refs.modalContent.contains(e.target)) {
                e.preventDefault();
                this.$refs.modalContent.focus();
            }
        },
        setupFocusTrap() {
            this.lastActiveElement = document.activeElement;
            this.$nextTick(() => {
                const focusableElements = this.$refs.modalContent.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
                if (focusableElements.length) {
                    focusableElements[0].focus();
                } else {
                    this.$refs.modalContent.focus();
                }
            });
            document.addEventListener('focus', this.trapFocus, true);
            // Set the header's z-index lower when modal is open
            const headerElements = document.querySelectorAll('header, .header, [class*="header"]');
            headerElements.forEach(el => {
                el.style.setProperty('z-index', 'auto', 'important');
            });
        },
        removeFocusTrap() {
            document.removeEventListener('focus', this.trapFocus, true);
            if (this.lastActiveElement) {
                this.lastActiveElement.focus();
            }
            // Restore the header's z-index when modal is closed
            const headerElements = document.querySelectorAll('header, .header, [class*="header"]');
            headerElements.forEach(el => {
                el.style.removeProperty('z-index');
            });
        },
        moveToBody() {
            // Only move to body if not already a direct child of body
            if (this.$el.parentNode !== document.body) {
                document.body.appendChild(this.$el);
            }
        }
    },
    watch: {
        active(newVal) {
            if (newVal) {
                // Move modal to body to avoid stacking context issues
                this.$nextTick(() => {
                    this.moveToBody();
                    this.setupFocusTrap();
                    // Add class to body to prevent scrolling
                    document.body.classList.add('modal-open');
                });
            } else {
                this.removeFocusTrap();
                // Remove class from body when modal is closed
                document.body.classList.remove('modal-open');
            }
        }
    },
    mounted() {
        document.addEventListener('keydown', this.handleEscKey);
        // Move to body on mount to avoid stacking context issues
        this.$nextTick(() => {
            this.moveToBody();
        });
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleEscKey);
        this.removeFocusTrap();
        // Remove the element from DOM when component is destroyed
        if (this.$el && this.$el.parentNode) {
            this.$el.parentNode.removeChild(this.$el);
        }
    }
};
