// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/mejs/mejs-controls-bette.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".downloadbox{--tw-bg-opacity:1;background-color:rgba(255,255,255,var(--tw-bg-opacity));border:1px solid #e3e3e3}.downloadbox__head{position:relative;display:block;padding-bottom:56.3%;background:linear-gradient(#d8d9d9,#889396);overflow:hidden}.downloadbox__image{position:absolute;width:100%;transition:transform .8s;top:50%;left:50%;transform:translate(-50%,-50%)}.downloadbox__video{position:relative}.downloadbox__poster-button{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");position:absolute;top:50%;left:50%;z-index:10;color:transparent;width:80px;height:80px;transform:scale(1.6) translate(-50%,-50%);background-position:0 -39px;transform-origin:left top;transition:transform .32s cubic-bezier(.4,0,.2,1)}.downloadbox__poster-button:hover{transform:scale(1.75) translate(-50%,-50%);background-position:-80px -39px}.downloadbox__headline{margin-bottom:0;padding:2rem 2rem 0}.downloadbox__items{padding:2rem}.downloadbox__items--certtypes{margin-bottom:1rem}.downloadbox__item{display:flex;flex-wrap:wrap}.downloadbox__item:not(:last-child){margin-bottom:1rem}.downloadbox__filetype{flex:1 0 40%;white-space:nowrap;margin-right:1rem}.downloadbox__options{margin-right:-1rem;margin-left:-1rem;display:flex;white-space:nowrap}.downloadbox__options>*{flex:0 0 auto;margin-right:1rem;margin-left:1rem;display:flex;align-items:center;justify-content:center;background-image:none}.downloadbox__options>:hover{background-image:none}", ""]);
// Exports
module.exports = exports;
