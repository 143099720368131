import { render, staticRenderFns } from "./ProductTeaser.vue?vue&type=template&id=6269f270&"
import script from "./ProductTeaser.vue?vue&type=script&lang=js&"
export * from "./ProductTeaser.vue?vue&type=script&lang=js&"
import style0 from "./ProductTeaser.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Picture: require('/usr/src/app/components/Configurator/components/Picture.vue').default})
