//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImageCopyright from '../Partials/ImageCopyright.vue';
import TextBlock from '../Partials/TextBlock.vue';
import AudioPlayer from '../Partials/AudioPlayer.vue';

export default {
    name: 'ImageTeaser',
    components: { TextBlock, ImageCopyright, AudioPlayer },
    props: ['data'],
    computed: {
        image() {
            return this.$mapImageData(this.data?.image?.image);
        },
    },
};
