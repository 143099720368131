import { render, staticRenderFns } from "./ImageTeaser.vue?vue&type=template&id=5961ce16&"
import script from "./ImageTeaser.vue?vue&type=script&lang=js&"
export * from "./ImageTeaser.vue?vue&type=script&lang=js&"
import style0 from "./ImageTeaser.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageCopyright: require('/usr/src/app/components/Partials/ImageCopyright.vue').default,Picture: require('/usr/src/app/components/Configurator/components/Picture.vue').default,TextBlock: require('/usr/src/app/components/Partials/TextBlock.vue').default,AudioPlayer: require('/usr/src/app/components/Partials/AudioPlayer.vue').default})
