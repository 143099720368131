//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import LanguageSwitch from '../LanguageSwitch.vue';
export default {
    name: 'FooterNavigationMeta',
    components: { LanguageSwitch },
    computed: {
        ...mapGetters(['getStoredSiteProperties']),
    },
    data() {
        return {
            meta: null,
        };
    },
    created() {
        try {
            this.meta = this.getStoredSiteProperties?.footer?.meta;
        } catch (e) {
            console.error(e);
        }
    },
};
